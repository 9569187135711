::-webkit-scrollbar{
	width:4px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: rgb(255 255 255 / 12%);
}
::-webkit-scrollbar-thumb{
	background-color: rgb(255 255 255 / 32%);
	border-radius: 4px;
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: rgb(255 255 255 / 12%);
}



.alert-checkbox-group::-webkit-scrollbar {
    width: 4px;
    display: block !important;
}

.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.alert-radio-group::-webkit-scrollbar {
    width: 4px;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


// Navigation
#navigation::-webkit-scrollbar{
	width:4px;
	height:9px;
}
#navigation::-webkit-scrollbar-button:start:decrement,
#navigation::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
#navigation::-webkit-scrollbar-track-piece{
	background-color: rgb(255 255 255 / 12%);
}
#navigation::-webkit-scrollbar-thumb{
	background-color: rgb(255 255 255 / 32%);
	border-radius: 4px;
}
#navigation::-webkit-scrollbar-thumb:vertical{
}
#navigation::-webkit-scrollbar-thumb:horizontal{
}
#navigation::-webkit-scrollbar-thumb:hover{
	background-color: rgb(255 255 255 / 12%);
}


// Mat option panel
.mat-select-panel::-webkit-scrollbar{
	width:4px;
	height:9px;
}
.mat-select-panel::-webkit-scrollbar-button:start:decrement,
.mat-select-panel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
.mat-select-panel::-webkit-scrollbar-track-piece{
	background-color: rgb(0 0 0 / 12%);
}
.mat-select-panel::-webkit-scrollbar-thumb{
	background-color: rgb(0 0 0 / 66%);
	border-radius: 4px;
}
.mat-select-panel::-webkit-scrollbar-thumb:vertical{
}
.mat-select-panel::-webkit-scrollbar-thumb:horizontal{
}
.mat-select-panel::-webkit-scrollbar-thumb:hover{
	background-color: rgb(0 0 0 / 66%);
}
