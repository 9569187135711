.mat-radio-input {
    margin: 5px !important;
    display: flex;
}

.mat-tab-label {
    margin: 1px;
}



.mat-ink-bar {
    transition: none !important;
}

.show-password {
    background: transparent !important;
}

.mat-datepicker-toggle-default-icon {
    color: #fff;
}

input[type=time] {
    cursor: text !important;
}

input.searchbar-input {
    background: #ffffff1c !important;
    border-radius: 4px !important;
}

input[type=password] {
    border: none;
    width: 100%;
}

.fake-dropdown .mat-form-field-prefix, .mat-form-field-suffix {
    top: -16px;
}

.date-input.fake-dropdown input,
input:not([type=radio]):not(.mat-date-range-input-inner):not([type=range]),
input[type=file],
input[type=password],
.native-textarea,
.native-textarea.sc-ion-textarea-md,
textarea,
ion-select,
select,
.native-input {
    background: #ffffff1c; // !important;
    border: none;
    border-radius: 4px !important;
    padding: 10px;
    opacity: 1 !important;

    input::placeholder {
        color: #fff;
    }
}

.sc-ion-textarea-md-h,
.sc-ion-textarea-md-s {
    margin: 0px;
}

.mat-datepicker-input {
    max-width: 110px !important;
}

textarea {
    width: 100%;
    display: block;
    border: none !important;

    &:focus {
        border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
    }
}

.mat-select {
    background: #ffffff1c !important;
    color: #fff;
    padding: 14px !important;
    border-radius: 4px !important;

    * {
        color: #fff;
    }
    &:focus {
        border-radius: 4px;
        stroke: #fff;
        outline: 1px solid #fff ;
      }

}

.mat-select-panel {
    background: #2b2b2b;

    * {
        color: #efefef;
    }

    mat-option {
        transition: none;
        border-top: 1px solid #ffffff17 !important;

        &:hover {
            background: #66666666 !important;
        }
    }
}

#organisations-panel, #attendanceType-panel, #skills-panel {
        
    mat-option.mat-active {
        color: #efefef !important;
        background: #2b2b2b !important;

        &:hover {
            background-color: #66666666 !important;
        }
    }
}

.mat-select-panel-wrap {
    box-shadow: 0px 0px 5px 0px #00000042;
}

input:focus{
    border-radius: 4px;
    stroke: #fff;
    outline: 1px solid #fff ;
}

select {
    appearance: none;
    display: block;
    width: 100%;
    border: none !important;
}

textarea::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #fff !important;
}

ion-label.mt15 {
    margin-top: 15px !important;
}

ion-label {
    padding-top: 10px;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    text-overflow: initial !important;
    white-space: initial !important;
}

.filter-wrapper {
    margin: 10px 0px;

    .filter-label {
        padding: 10px;
    }

    /* ion-label {
        padding-top: 10px;
    } */

    .filter-value {
        padding: 10px 20px;
        color: #fff;
        margin: 0px 5px;
        font-size: 1em;
    }

    .datepicker-trigger {
        padding: 0px 20px;
    }
}

.mat-form-field-flex {
    margin-top: 10px;
    padding-left: 0 !important;
    padding-top: 0 !important;
}

input.searchbar-input::placeholder,
input::placeholder {
    color: #fff;
}

.mat-datepicker-content {
    background-color: #141c26fc !important;

    button {
        color: #efefef !important;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background: #efefef !important;
}

.mat-datepicker-content .mat-calendar {
    * {
        color: #fff !important;
    }

}

ion-datetime .item.sc-ion-label-md-h,
ion-datetime .item .sc-ion-label-md-h {
    color: #fff !important;

    * {
        color: #fff !important;
    }
}

ion-datetime {
    color: #fff !important;

    .datetime-calendar,
    .datetime-calendar *,
    * {
        color: #fff !important;
    }

    background-color: #141c26fc !important;
}


input::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}

ion-icon.searchbar-search-icon {
    right: 8px !important;
    position: absolute !important;
    display: flex;
    left: auto !important;
    top: 50% !important;
    transform: translateY(-50%);
    color: #fff !important;
}

.searchbar-input,
.searchbar-input.sc-ion-searchbar-ios,
input.searchbar-input.sc-ion-searchbar-md {
    padding-left: 35px !important;
    color: #fff !important;
}

.date-input.fake-dropdown {
    // input {
    //     border-radius: 0px !important;
    //     background: transparent !important;
    //     background-color: transparent !important;
    // }
}



ion-select::part(placeholder) {
    color: #fff;
    opacity: 1;
}

ion-input::part(icon) {
    color: #fff;
    opacity: 1;
}

ion-input {
    --color: #fff !important;
    --placeholder-color: #fff !important;
}

.ion-md-time {
    color: red !important;
}

.ion-ios-time {
    color: red !important;
}

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
    border: none !important;
}

.mat-checkbox-frame {
    border-color: #fff;
}

.mat-tab-label-content {
    color: #fff;
    opacity: 1 !important;
}

.mat-tab-label-active {
    opacity: 1 !important;

    * {
        opacity: 1 !important;
        color: #fff !important;
    }
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 5px !important;
    padding: 0px !important;
    background: #5050ba !important;
    outline: none;
    margin-top: 15px;
}

input[type=range]:hover {
    opacity: 1;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #5050ba;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #5050ba;
    cursor: pointer;
}

.mat-button svg {
    fill: #fff;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #5050ba;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #5050ba;
    background-color: #5050ba;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: #ffffff !important;
}

ion-item::part(item-inner) {
    opacity: 0 !important;
}

.mat-radio-outer-circle {
    border-color: rgba(255, 255, 255, 0.54) !important;
}

.filter-value {
    padding: 5px 10px;
    font-size: 0.9em;
    background: #ffffff17;
    border-radius: 4px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #fff !important;
}

.mat-pseudo-checkbox-checked {
    background: #5050ba;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #5050ba;
}

.alert-button-inner {
    font-size: 1.23em;
}

.mat-form-field-label-wrapper {
    top: -20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.mat-date-range-input-mirror {
    min-width: 55px !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 999999999 !important;
}


.select-popup-overlay {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: block;
        z-index: 90;
    }
}

input[type='time']::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="white" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z"/></svg>');
    background-color: transparent;
    font-size: 1.2em !important;
}

.mat-option {
    white-space: normal !important;
    line-height: normal !important;
    height: unset !important;
    padding-top: 0.9em !important;
    padding-bottom: 0.9em !important;
    font-size: 16px;
}

svg.mat-calendar-arrow {
    fill: #fff;
}

fieldset {
    border: none !important;
}

.mat-option.mat-active {
    background: #666666 !important;

    * {
        color: #fff !important
    }
}

.required-fields-explainer {
    font-size: 0.8em;
}

.mat-checkbox.cdk-focused {
    .mat-checkbox-inner-container {
        outline: 1px solid #fff !important;
        border: 1px solid #000 !important;
    }
}

.mat-radio-input {
    margin: 0px !important;
}

.mat-form-field-underline {
    display: none !important;
}

.mat-form-field-label {
    margin-top: -4px;
}



.invalid-field {
    border: 1px solid #921546 !important;
}
// .ng-submitted {
//     mat-select.ng-invalid,
//     input.ng-invalid {
//         border: 1px solid #921546 !important;
//     }
// }