// @import 'scss/fonts';
@import 'scss/mixins';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import 'scss/grid';
@import 'scss/fonts';
@import 'scss/scrollbar';
@import 'scss/buttons';
@import 'scss/inputs';
@import 'scss/copilot';
@import 'scss/navigation';
@import 'scss/layout';
@import 'scss/responsive';
@import 'scss/accessibility';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  height: 100%;
  margin: 0;
}

