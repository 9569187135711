// Navigation
#navigation {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  .nav-link,
  a {
    padding: 10px 10px;
    transition: all 0.35s;
    font-size: 1em;
    display: block;
    font-weight: bolder;
    border-radius: 4px;
    text-align: center;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      white-space: nowrap;
      text-align: left;
      border-radius: 4px;
      padding: 10px 20px;
      background-color: #5050ba;
      transition: all 0.20s;
      color: #fff;
      font-size: 0.7em;
      font-weight: bold;
      top: 50%;
      box-shadow: 0px 0px 30px 0px #00000075;
      right: -20px;
      transform-origin: 0% 50%;
      opacity: 0;
      transform: translateY(-50%) translateX(100%) scale(0.2);

      &:before {
        content: '';
        display: block;
        border: 6px solid transparent;
        border-right-color: #5050ba;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -11px;
      }
    }

    &:hover {
      background-color: #ffffff12;

      span {
        opacity: 1;
        transform: translateY(-50%) translateX(100%) scale(1);
      }
    }

    &.active {
      background: #ffffff12;
    }
  }

  i {
    font-size: 1.5em !important;
  }

  .qr-button, .org-button {
    color: #fff;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;

    span {
      font-size: 0.85em;
      font-weight: bold;
      color: #fff !important;
      padding-left: 10px;
    }

    i {
      color: #fff !important;
      font-size: 1.0625em !important;
    }
  }

  * {
    //color: #9fa5b0;
    color: #fff;
    font-weight: 500;
  }

  .user-name {
    color: #fff;
  }

}




#logo {
  width: 100px;
  margin: 0 auto;
  display: block;
}

.navigation-inner{
  li{
    align-items: center;
    justify-content: center;
    display: flex;
  }
}


// #user-menu {
//   display: flex !important;
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
//   right: 30px;
//   color: #fff;

//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;

//   i {
//     cursor: pointer;
//     color: #fff;
//     font-size: 1.4375em !important;
//   }
// }

.back-button {
  position: fixed;
  bottom: 20px;
  z-index: 9999999;
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #fff !important;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border-radius: 30px;
  display: flex;
  padding: 10px;
  text-align: center;
  left: 15px;
}



@media only screen and (max-width: 820px) {

  #mobile-menu-button {
    display: block !important;
    padding: 0 !important;
    position: fixed;
    background-color: transparent;
    bottom: 4px;
    z-index: 99999999;
    left: 50%;
    transform: translateX(-50%);
    i {
      font-size: 1.5em;
      color: #fff;
    }
  }

  #app-container {
    transition: all 1s;
    transform: translateY(0%);
  }

  //active-mobile-menu 
  app-navigation {
    top: auto !important;
    width: 100% !important;
    height: auto !important;
    bottom: 0px !important;
    border-right: none !important;
    border-top: 1px solid #ffffff17;

    position: static !important;
  }

  #navigation {
    height: 20px !important;
    max-height: 20px;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    transition: all 500ms;
    padding-bottom: 35px;
    left: 0px;
    background: #121723;
    box-shadow: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 0px;
    border-top: 1px solid #ffffff17;
    border-radius: 0px;

    .bi-x,
    .bi-list {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .navigation-inner {
    display: none;
    position: absolute;
    height: 100vh;
    bottom: 0px;
    align-items: center;
    justify-content: center;
  }

  .active-mobile-menu {
    
    #app-container {
      transform: translateY(-100%);
    }

    #navigation {
      max-height: 100%;
      //background: #1f1a3be3;
      /* background: #0c0822; */
      box-shadow: 0px 0px 60px 0px #000;
      height: 100vh !important;

      .navigation-inner {
        display: flex !important;
      }
    }
  }


  #navigation a {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ffffff17;
    border-radius: 0px;
    margin: 0px !important;

    span {
      position: relative;
      opacity: 1 !important;
      transform: none !important;
      top: auto !important;
      left: auto !important;
      display: inline-block !important;
      background: transparent;
      box-shadow: none;
      padding-left: 0px;
      font-size: 16px;

      &:before {
        display: none !important;
      }
    }
  }
}

//Zoom fix
@media only screen and (max-height: 400px) {

  #mobile-menu-button {
    bottom: 1px;
  }

  #navigation {
    padding-bottom: 27px;
  }

  #navigation a {
    padding: 0px 5px;
    
    i {
      font-size: 1em !important;
      padding-bottom: 0px;
    }

    span {
      font-size: 12px;
      padding-bottom: 0px;
    }
  }
}

//Zoom fix
@media only screen and (max-height: 180px) {

  .navigation-inner {
    position: fixed !important;
    bottom: 30px !important;
    overflow: auto !important;
    top: 0px;
    height: calc(100vh - 27px);
    justify-content: normal !important;
  }
}