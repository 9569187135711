/** 
* Inkpath Buttons
*/
//Dark / Normal Mode
.button {
    padding: 10px 20px;
    font-size: 0.8em !important;
    text-align: center;
    display: flex;
    position: relative;
    background: transparent;
    font-weight: 560;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: 47px;
    margin: 2px;
    transition: all 0.2s;
    color: #fff;

    &.active-tab{
        position: relative;
    }
    &.active-tab:before{
        content: '';
        height: 2px;
        display: block;
        background-color: #fff;
        position: absolute;
        width: 100%;
        bottom: 8px;
    }

    &.small-button{
        min-height: 0px;
        padding: 5px !important;
        *{
            color: #fff !important
        }
        i{
            height: 19px;
        }
    }

    &.filter-button{
        min-height: 0px;
        padding: 2px 5px !important;
        *{
            color: #fff !important
        }
        i{
            height: 19px;
        }
    }

    &.p5{
        min-height: 0px;
        padding: 5px !important;
    }

    span:not(.mat-badge-content) {
        transition: all 0.2s;
        display: flex;
        color: #fff;
        position: relative;
        padding: 0px 10px;
        height: 100%;
        display: flex;
        align-items: center;
    }

    i {
        transition: all 0.2s;
        display: flex;
        font-size: 1.6em;
    }

    &.tab{
        padding: 10px 10px;
    }

    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &.primary {
        background: #5050ba;
        border: 2px solid #5050ba;

        i {
            color: #fff;
        }

        &:hover {
            background: #4747a6;
            border: 2px solid #4747a6;
        }
    }

    &.error {
        background: #9E0054;
        border: 2px solid #9E0054;

        i {
            color: #fff;
        }

        &:hover {
            background: #9E0054;
            border: 2px solid #9E0054;
        }
    }

    &.success {
        background: #10a2a4;
        //#009ecc
        border: 2px solid #10a2a4;

        i {
            color: #fff;
        }

        &:hover {
            background: #0e9496;
            border: 2px solid #0e9496;
        }
    }

    &.secondary {
        border:2px solid #2c2d5e;
        background: #2c2d5e;

        i {
            color: #fff;
        }

        &:hover {
            border: 2px solid 4747a6;
            background: #4747a6;
        }
    }

    &.card{
        margin: 4px auto;
    }

    &.card-orgs {
        margin: 0px;
        padding: 14px 10px;
        font-size: 1em !important;
        font-weight: normal;
        border-radius: 0px ;
        min-height: 53px ;
        border-top: 1px solid #ffffff17;
        max-width: 100%;
        justify-content: left !important;
        text-align: left;

        &:hover {
            transition: none;
            background-color: #66666666 !important;
        }
    }

    &.rating-stars {
        padding: 2px !important;
    }

    &.modal-close {
        position: absolute;
        background: transparent;
        color: #fff;
        padding: 5px;
        top: 0;
        right: 0;
        justify-content: right;
        z-index: 9999;
        width: 80px;

        i {
            padding: 0;
            margin: 0;
        }

        span {
            padding: 0;
        }

        &:focus {
            border: none !important;
        }
    }

    &.modal-back {
        position: absolute;
        background: transparent;
        color: #fff;
        padding: 5px;
        top: 0;
        left: 0;
        justify-content: left;
        z-index: 9999;
        width: 80px;

        i {
            padding: 0;
            margin: 0;
        }

        span {
            padding: 0 5px;
        }

        &:focus {
            border: none !important;
        }
    }

    &.announcements-back {
        position: relative;
        background: transparent;
        color: #fff;
        padding: 0px;
        top: 0px;
        left: -5px;
        justify-content: left;
        z-index: 9999;
        width: 80px;
    
        i {
            padding: 0;
            margin: 0;
        }
    
        span {
            font-weight: 500;
            font-size: 14px;
        }
    
        &:focus {
            border: none !important;
        }
    }

    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #fff !important;
    }

    &.announcements-bell {
        width: 35px;
        right: 310px;
        top: 15px;
        position: fixed;
        z-index: 999;

        i {
            font-size: 1.2em;
        }

        span.mat-badge-content {
            top: 0px !important;
            right: 0px !important;
            background: #a00 !important;
            color: #fff !important;
            font-style: normal !important;
            padding: 0px !important;
        }
    }

    &.announcements-tab {
        span.mat-badge-content {
            top: -5px !important;
            right: -5px !important;
            background: #a00 !important;
            color: #fff !important;
            font-style: normal !important;
            padding: 0px !important;
        }
    }
}


/** 
* Ionic Buttons
*/
.alert-button {
    padding: 10px 20px;
    font-size: 0.8em !important;
    text-align: center;
    display: flex;
    background: transparent;
    max-width: 400px;
    font-weight: 560;
    border-radius: 4px !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: 45px;
    margin: 2px;
    transition: all 0.2s;
    color: #fff !important;
    background: #5050ba !important;
    border: 2px solid #5050ba !important;

    * {
        display: flex;
        color: #fff;
    }

    &:hover {
        background: #4747a6 !important;
        border: 2px solid #4747a6 !important;
    }
}

.alert-button-inner{
    text-transform: capitalize !important;
}