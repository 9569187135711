/** 
* High Contrast Mode
*/
.high-contrast-mode {

    .high-contrast-check {
        font-size: 14px !important;
        height: 12px;
    }

    a {
        color: #8AB7FF !important;
    }

    /* .date-range {
        font-size: 0.5em !important;
    } */

    .inkpath-modal .ion-page {
        background-color: #111 !important;
        border: 1px solid rgba(255, 255, 255, 0.3);

        h1, p {
            background-color: #111 !important;
        }
    }

    .alert-wrapper {
        background: #111 !important;
        color: #fff !important;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .display-mode i {
        color: #fff !important;
    }

    * {
        transition: none !important;
        animation: none !important;
    }

    .close-walkthrough,
    input:focus,
    mat-checkbox.cdk-focused,
    *[tabindex]:focus {
        border-radius: 4px;
        stroke: #DC00F0;
        outline: 2px solid #DC00F0 !important;
        outline-style: inset !important;
    }

    .mat-tab-header .mat-tab-label,
    ion-segment-button {
        margin: 3px;
    }

    .grid {
        padding: 3px !important;
    }

    .app-bg {
        background: #000 !important;

        &:after,
        &:before {
            display: none !important;
        }
    }

    .mat-tab-labels {
        background: #000 !important;
        border-radius: 8px 8px 0px 0px !important;
    }

    .mat-ink-bar {
        background-color: #fff !important;
        color: #fff !important;
    }

    .animate__animated.animate__bounceIn.copilot-view {
        background: #111 !important;
        border: 1px solid rgba(255, 255, 255, 0.3);

        .walkthrough-content p {
            background: #111 !important;
        }
    }

    .main-title {
        color: #fff;
        background-color: #000 !important;
    }

    .filter-toggle,
    .page-title {
        color: #fff;
        background-color: #000;
    }

    .activity-card:before {
        box-shadow: none;
    }

    .footer {
        padding: 5px 10px;
        background: #2b2b2b;
        border-radius: 4px 0px 0px 0px;
    }

    input[type=password],
    input[type=password].native-input.sc-ion-input-md,
    .mat-select,
    input,
    input[type=file],
    textarea,
    ion-select,
    select,
    .native-input {
        background: #000 !important;
        opacity: 1 !important;
        border: 1px solid #2b2b2b !important;
        outline: none;

        &:focus {
            border: 2px solid #2b2b2b !important;
            outline: 2px solid #f04dff !important;
        }
    }

    .range-knob-handle {
        outline-style: solid !important;
        outline-width: 2px !important;
    }

    ion-range::part(knob):focus {
        outline-style: solid !important;
        outline-width: 2px !important;
    }

    .range-knob-handle:focus {
        outline: 2px solid #f04dff !important;
    }

    button {
        border: none !important;

        &:focus {
            outline: 2px solid #f04dff !important;
        }
    }

    p {
        color: #fff !important;
        background: #000 !important;
    }

    .important-info-box,
    .important-info {
        background: #2b2b2b !important;
        border: none !important;
    }

    ion-label {
        background-color: #000;
    }


    .skill,
    .dash-button {
        background: #2b2b2b !important;
    }

    .booking-status {
        /* font-size: 0.875em !important; */
        font-weight: bold !important;
        background: #2b2b2b !important;
    }

    .card-title {
        background-color: #000;
        color: #fff;
        /* font-size: 1.25em; 
        font-weight: bold; */
    }

    .high-contrast-text {
        background-color: #000 !important;
        color: #fff !important;
    }

    .boxed,
    .graph,
    .question-item,
    .skills,
    .dash-card,
    .goal-title,
    .filter-wrapper,
    .card {
        border-radius: 8px;
        background-color: #000 !important;
        border: 1px solid rgba(255, 255, 255, 0.105);
    }

    h1 {
        background-color: #000 !important;
        color: #fff !important;
    }

    .activity-card {
        background-color: #000;
        border: 1px solid rgba(255, 255, 255, 0.105);
    }

    // #user-menu {
    //     background: #5050ba;
    //     color: #fff;
    //     border-radius: 4px;
    //     padding-left: 10px;
    //     padding-right: 10px;

    //     i {
    //         color: #fff;
    //     }
    // }

    .card-title {
        span {
            font-weight: 500;
            /* font-size: 1.125em; */
        }
    }

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        background: #5050ba !important;
        height: 5px !important;
        padding: 0px !important;
        height: 5px !important;

        &:focus {
            outline: 2px solid #f04dff !important;
        }
    }

    input[type=range]:hover {
        opacity: 1;
    }

    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #5050ba;
        cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #5050ba;
        cursor: pointer;
    }

    .high-contrast-button {
        background: #000;
        border-radius: 4px;
    }

    .important-info-box {
        .card-title {
            background-color: #2b2b2b !important;
        }
    }

    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #000 !important;
    }

    #top-right-org-label {
        color: #fff !important;
        font-weight: 400 !important;
    }
    
    .announcements #message {
        * {
            color: #fff !important;
        }
    }
}

/** 
* Dark Mode
*/
.dark-mode {
    .wysiwyg-content,
    .section-details,
    .boxed{
        color: #fff !important;

        *{
            color: #fff !important;
        }
    }

    a {
        color: #8AB7FF !important;
    }

    .close-walkthrough:focus {
        outline: 2px solid #fff !important;
    }

    .section-additional-info {
        * {
            color: #fff !important;
        }
    }

    .announcements #message {
        * {
            color: #fff !important;
        }
    }
}

/** 
* Light Mode
*/
.light-mode {

    .searchbar-clear-icon{
        color: #2b2b2b;
    }

    /* .mat-datepicker-input {
        background: #fff;
        border: 1px solid #efefef;
        border-radius: 4px;
    } */

    .more{
        color: #fff !important;
    }

    .section-additional-info {
        * {
            color: #000 !important;
        }
    }

    .button {
        &.card,
        &.transparent {
            color: #2b2b2b !important;

            span {
                color: #2b2b2b !important;
            }

            i {
                color: #2b2b2b !important;
            }
        }

        &.announcements-bell {
            span.mat-badge-content {
                color: #fff !important;
            }
        }

        &.announcements-tab {
            span {
                color: #000 !important;

                .mat-badge-content {
                    color: #fff !important;
                }
            }
        }

        &.announcements-back {
            * {
                color: #000 !important;
            }
        }

        &.active-tab:before{
            background-color: #2b2b2b !important;
        }

        &.modal-back,
        &.modal-close {
            background-color: #efefef !important;
            color: #2b2b2b !important;

            span, i {
                color: #2b2b2b !important;
            }
        }

        &.card-orgs {
            background-color: #efefef !important;
            color: #2b2b2b !important;
            border-top: 1px solid #2d374617 !important;

            &:hover {
                transition: none;
                background-color: #66666666 !important;
            }

            span {
                color: #2b2b2b !important;
            }
        }
    }

    #org-menu {
        background-color: #efefef !important;
    }

    .graph,
    .question-item,
    .activity-select-item {
        background-color: rgb(0 0 0 / 6%) !important;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="black" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z"/></svg>');
        background-color: transparent;
    }

    .main-title {
        border-bottom: 1px solid #2d374617;
    }

    .mat-date-range-input-separator,
    .tab-button,
    .checkbox-label {
        color: #000 !important;
    }

    .mat-checkbox-checked .mat-checkbox-background {
        background: #5050ba;
    }

    .mat-select-panel, .mat-menu-panel {
        background: #fff !important;
    
        * {
            color: #2b2b2b !important;
        }
    
        mat-option {
            transition: none;
            border-top: 1px solid #2d374617 !important;
        }

        mat-option.mat-active {
            background-color: #d8d8d8 !important;
        }
    
        mat-option:hover {
            background-color: #efefef !important;
        }
    }

    #organisations-panel, #attendanceType-panel, #skills-panel {
        
        mat-option.mat-active {
            color: #2b2b2b !important;
            background: #fff !important;

            &:hover {
                background-color: #efefef !important;
            }
        }
    }

    .alert-wrapper {
        width: 500px !important;
        max-width: 100%;
        background: #fff !important;
        color: #2b2b2b !important;

        .alert-message {
            color: #2b2b2b !important;

            * {
                color: #2b2b2b !important;
            }
        }
    }

    color: #2b2b2b;

    .inkpath-modal .ion-page {
        background-color: #fff !important;
        color: #000;
        // .mat-radio-label-content{
        //     color: #000 !important;
        // }
    }

    .mat-ink-bar {
        background: #00000063;
    }

    .mat-select * {
        color: #2b2b2b;
    }

    .filter-label,
    .mat-checkbox-label,
    .filter-toggle {
        color: #000;

        * {
            color: #000;
        }
    }

    .inHeader *:not(.button.error span, .button.error i, .mat-badge-content),
    .inkpath-modal a {
        color: #000 !important;
    }
    
    .date-input.fake-dropdown input,
    input:not([type=radio]):not([type=range]):not(.mat-date-range-input-inner),
    input[type=file],
    textarea,
    ion-select,
    select,
    .mat-select {
        &::placeholder {
            color: #000 !important;
        }

        background: #dadae5 !important;
        color: #000 !important;
       
    }

    .mat-datepicker-content .mat-calendar {
        * {
            color: #000 !important;
        }

        background: #fff !important;

        span.mat-calendar-body-selected {
            color: #fff !important;
        }
    }

    .mat-form-field-label, .mat-label,
    .date-input.fake-dropdown .mat-form-field-label{
        color: #000 !important;
    }

    .important-info-box {
        background-color: #ff0471;
        color: #fff !important;

        &.clash {
            background-color: #fd8402;
        }

        * {
            color: #fff !important;
        }
    }

    /* .important-info-box {
        background-color: #cd045c;

        * {
            color: #fff !important;
        }
    } */

    .booking-status.not-booked {
        color: #2b2b2b !important;
    }

    .search-form input {
        font-size: 0.85em;
        width: 100%;
        padding: 5px 10px !important;
        color: #2b2b2b !important;
        background: #85858533 !important;

        &::placeholder {
            color: #2b2b2b !important;
        }
    }

    .search-button {
        i {
            color: #2b2b2b !important;
        }
    }

    .close-walkthrough,
    input:focus,
    *[tabindex]:focus {
        border-radius: 4px;
        stroke: #000;
        outline: 1px solid #000 !important;
        box-shadow: inset 0px 0px 0px 1px #fff !important;
    }

    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
        background-color: #000000 !important;
    }

    .mat-radio-outer-circle {
        border-color: rgba(0, 0, 0, 0.54) !important;
    }

    .mat-select {
        background: #dadae5 !important;
    }

    .fake-dropdown {
        position: relative;

        .bi-caret-down-fill {
            color: #000 !important;
        }
    }

    .mat-checkbox-frame {
        border-color: #000;
    }

    .mat-tab-label-content {
        color: #000;
        opacity: 1 !important;
    }

    .mat-tab-label-active {
        opacity: 1 !important;

        * {
            opacity: 1 !important;
            color: #000 !important;
        }
    }

    input.searchbar-input {
        background: #fff !important;
        border-radius: 4px !important;
        color: #000 !important;
    }

    input.searchbar-input+ion-icon {
        color: #000 !important;
    }

    .filter-toggle {
        background: #dad8d89c !important;
    }

    .app-bg {
        background: #fff !important;
    }

    .activity-card {
        background: #fff !important;
        box-shadow: none !important;
        color: #000 !important;
    }

    .thumb-up,
    .thumb-down,
    .bi-star-fill,
    .bi-star {
        filter: invert(100%);
    }

    #navigation a span {
        box-shadow: none !important;
        color: #fff !important;
    }

    .active-mobile-menu #navigation a span {
        color: #000 !important;
    }

    .goal-title {
        background: #0000001f !important;
    }

    ion-select::part(placeholder),
    .native-input input::placeholder,
    .mat-form-field-appearance-legacy .mat-form-field-label,
    .filter-wrapper .filter-value,
    ion-label,
    //*:not(.button):not(.top-button):not(.reflection-submit):not(.copilot-custom):not(.completeContainer):not(.skill),
    .card h2,
    .clickable,
    .goal-title,
    .goal-title *,
    .user-name,
    .activity-card *,
    .card-title,
    .main-title,
    .page-title {
        color: #000;

        * {
            color: #000;
        }
    }

    .graph {
        //background: #1d1c1c1c !important;

        * {
            color: #000 !important
        }
    }


    .show-password,
    .navigation-inner,
    .activity-card {
        color: #000 !important;

        i {
            color: #000 !important;
        }

        * {
            color: #000 !important;
        }
    }

    /* .navigation-inner {

        span {
            color: #fff !important;
        }
    } */

    .top-menu.apply-light, .account-menu.apply-light {
        background: #fff !important;
      
        &:before{
          border-bottom-color: #efefef !important;
        }
      
        .top-menu-title, .account-menu-title {
            background-color: #efefef !important;
            color: #1f2842 !important;
        }
      
        .top-menu-list {
      
            button {
                color: #1f2842 !important;
            
                &:hover {
                    background-color: rgb(123 132 157 / 15%) !important;
                }
            }
        }
    }

    .top-right-menu {
        color: #fff !important;

        * {
            color: #fff !important;
        }
    }

    #top-right-org-label {
        color: #555 !important;
        font-weight: 500 !important;
    }

    .skill {
        color: #fff !important;
    }

    #navigation .qr-button, #navigation .org-button {
        color: #fff !important;

        * {
            color: #fff !important
        }
    }

    .prev,
    .next,
    .dash-button,
    .reflection-submit,
    .copilot-custom {
        color: #fff !important;

        * {
            color: #fff !important;
        }
    }

    .progress-overall text {
        fill: #fff !important;
    }

    .time_logged *,
    .time_logged,
    //#navigation .nav-link span,
    //#navigation a span,
    .dash-button,
    .dash-button *,
    .courseFlag {
        color: #fff !important;
    }

    .progress-overall text.pie-chart-label,
    text {
        fill: #000 !important;
    }

    .chartLabel {
        fill: #2b2b2b !important;
    }

    p {
        color: #2b2b2b !important;
    }

    .help-text {
        background-color: #c9ccdee6 !important;
    }

    .dash-button {
        box-shadow: none !important;
    }

    .filter-wrapper,
    .card {
        background: #0000000f;
    }

    .dash-button-1 {
        background: #187f8f;
    }

    .dash-button-2 {
        background: #5050ba;
    }

    .dash-button-3 {
        background: #ac0faa;
    }

    .dash-button-4 {
        background: #2b2b2b;
    }

    /* #logo, #login-logo {
        filter: invert(1);
    } */

    .completeContainer,
    .important-info {
        color: #fff !important;

        * {
            color: #fff !important;
        }
    }

    .emptyBar {
        fill: #cecccc !important;
        stroke: #cecccc !important;
    }

    app-navigation {
        border-right: 1px solid #2d374617 !important;
    }

    .mat-datepicker-toggle-default-icon {
        fill: #000;
    }

    .alert-checkbox-group {
        padding: 4px;
    }

    .alert-checkbox-label {
        color: #000 !important;
    }

    [aria-checked=true].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md {
        background-color: #5050ba;
        border-color: #5050ba;
    }

    .main-title {
        border-bottom: 1px solid #2d374617 !important;
    }

    *::-webkit-scrollbar-track-piece {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.32) !important;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    input:not([type=radio]):not(.mat-date-range-input-inner) input[type=file],
    textarea,
    ion-select,
    .native-input {
        color: #000 !important;
        background: #b9bacc85 !important;

        &::placeholder {
            color: #000 !important;
        }
    }

    .app-bg {
        opacity: 0.8 !important;

        &:after,
        &:before {
            display: none !important;
        }
    }

    ion-segment-button {
        color: #000000 !important;
        border-bottom: 1px solid #00000017 !important;
        --color-hover: #000000 !important;
        --background-hover: rgba(0, 0, 0, 0.15);
        --color-focused: #000000 !important;
        --color-checked: #147098 !important;
        --border-color: #000000 !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background: #050505 !important;
    }

    .top-button {
        color: #fff !important;

        span {
            color: #fff !important
        }
    }

    .dash-card {
        color: #000 !important;
        background: #ffffff61 !important;
        box-shadow: none;
        border: 1px solid #1b1b1b;

        .dash-title {
            color: #000 !important;
        }

        p.dash-card-text {
            color: #000 !important;
            // background: #00000014 !important;
        }
    }

    [title='RatingRating'] {
        color: #000 !important;
    }

    .back-button {
        i {
            color: #000 !important
        }
    }

    .time_logged {
        color: #000 !important;

        * {
            color: #000 !important;
        }
    }

    .top-right-menu .display-mode i {
        color: #000 !important;
    }

    #mobile-menu-button {
        color: #000 !important;

        * {
            color: #000 !important;
        }
    }

    .mat-datepicker-toggle .mat-icon-button {
        color: #000 !important;

        * {
            color: #000 !important;
        }
    }

    .announcements {
        .selected {
            font-weight: 500 !important;
            color: #000;
        }

        .unselected {
            color: #000 !important;
        }

        /* li.pinned {
            background: rgba(0, 0, 0, 0.0288235294) !important;
        } */

        li.selecteditem {
            border: solid 1px #bbb !important;
            background: rgba(0, 0, 0, 0.0588235294) !important;
        }
    
        #message {
            * {
                color: #000 !important;
            }
        }
    }

    @media only screen and (max-width: 820px) {

        #navigation {
            background: #fff;
            border-top: 1px solid #00000017;

            a {
                border-bottom: 1px solid #00000017;
            }
        }

        #navigation.active {
            max-height: 100%;
            background: #fff;
            box-shadow: 0px 0px 60px 0px #000;
            height: 100vh !important;

            * {
                color: #000 !important;
            }
        }

        .activity-tabs .tab-button.active {
            background-color: rgba(45, 45, 45, 0.1) !important;
        }
    }
}

/** 
* Large Font
*/
.large-font {
    font-size: 1.05em !important;
}

/** 
* Static Theme
*/
.static-theme {
    .app-bg {
        //THESE ARE THE "DISCO LIGHTS" - NOW REMOVED
        /* background-image: url("/assets/images/bg.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; */

        //BACKGROUND COLOR OF ENTIRE SITE
        background-color: rgb(33, 32, 54);

        &:before,
        &:after {
            display: none !important;
        }
    }
}

/** 
* Simple Theme
*/
.simple-theme {
    .app-bg {

        &::before,
        &::after {
            display: none !important;
        }
    }

    .card {
        background: none !important;
    }
}

/** 
* Static Theme - Light Mode
*/
.simple-theme.light-mode {
    .activity-card {
        background: #dfdfdf9c !important;
    }
}

/** 
* Light Mode Responsive
*/
@media only screen and (max-width: 820px) {
    .light-mode .activity-tabs .tab-button.active {
        background-color: rgba(45, 45, 45, 0.1) !important;
    }
}