/** 
* Co Pilot styles
*/
:root {
  --zoneColor: rgba(20, 20, 20, 0.7) !important
}

// walkthrough
.animate__animated.animate__bounceIn.copilot-view {
  width: 350px;
  background: #202637 !important;
  border-radius: 8px !important;
  text-align: center !important;
  padding: 40px 20px 20px 20px !important;

  * {
    color: #efefef !important;
  }
}

.copilot-view {
  margin-left: -19px !important;
  margin-top: 60px !important;
}


// element focus background
.ngx-copilot-init.ngx-copilot-pulse {
  background: transparent !important;

}

.copilot-view {
  left: 50% !important;
  max-width: none !important;
  max-height: 100vh;
  border-radius: 0 !important;
  width: 500px !important;
  top: 50% !important;
  padding: 0 !important;
  overflow: auto;
  transform: translate(-50%, -50%) !important;

  /* .copilot-custom {
      display: flex !important;
      width: 85%;
      margin: 0 auto;
      transform: none;
      left: auto;
      padding: 0;
      top: auto;

      p {
          margin: 0 !important;
          padding-bottom: 10px !important;
      }
  } */
}

.copilot-custom {
  min-height: 300px;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  transform: none;
  left: auto;
  padding: 0;
  top: auto;

  h3 {
    margin: 0 0 10px 0 !important;
    color: #fff !important;
    font-size: 1.0625em !important;
  }

  p {
    margin-bottom: 10px !important;
  }
}

.footer-btn {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}


// Walkthrough Positions
.activities-step-2 {
  .copilot-view {
    margin-left: -200px !important;
  }
}

.walkthrough-center {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -250px;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
}

.close-walkthrough {
  background: rgb(0 199 204 / 39%);
  border-radius: 4px;
  position: absolute;
  transition: all 0.35s;
  font-weight: 500;
  /* opacity: 0.4; */
  top: 13px;
  right: 13px;
  color: #fff !important;
  padding: 4px 8px;
  font-size: 0.625em;
  cursor: pointer;

  background: #fff !important;
  font-size: 14px !important;
  padding: 2px 8px !important;
  min-width: 0 !important;
  position: absolute !important;
  height: auto !important;
  box-shadow: none !important;

  * {
    font-size: 0.625em !important;
  }

}


.walkthrough-step-2 {
  position: absolute;
  top: 18px;
  width: 40px;
  height: 40px;
  left: 241px;
}

.list-walkthrough {
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.walkthrough-step-6 {
  position: absolute; 
  top: 18px;
  width: 147px;
  height: 100px;
  right: 140px;
}

.walkthrough-step-8 {
  position: absolute;
  top: 18px;
  width: 147px;
  height: 100px;
  right: 0;
}

.walkthrough-step-9 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 500px;
  left: 0;
}

// .copilot-view-step-2 {
//   margin-left: 0 !important;
//   top: 20px !important;
// }

// .copilot-view-step-5 {
//   margin-left: -170px !important;
// }

// .copilot-view-step-10 {
//   margin-left: -250px !important;

// }

// .copilot-view-step-6 {
//   margin-left: -200px !important;
// }

// .copilot-view-step-7 {
//   margin-top: 7px !important;
// }

// .copilot-view-step-8 {
//   margin-left: -200px !important;
// }

// .copilot-view-step-9 {
//   margin-left: 30px !important;
// }

// .copilot-view-step-13 {
//   margin-top: -200px !important;
// }

// .copilot-view-step-16,
// .copilot-view-step-15,
// .copilot-view-step-14 {
//   top: auto !important;
//   position: fixed !important;
//   bottom: 200px !important;
// }

// .copilot-view-step-15 {
//   margin-left: -100px !important;
// }

// .copilot-view-step-27 {
//   margin-top: -131px !important;
// }

// .copilot-view-step-30 {
//   margin-left: -200px !important;
// }

// .copilot-view-step-18 {
//   margin-left: -300px !important;
// }

.walkthrough-tab-label{
  left: 50%;
  transform: translateX(-50%);
}






.ngx-wrapper-overview{
  z-index: 99999 !important;
  background: rgba(0, 0, 0, 0.5)  !important;
}