.walkthrough-content {
    // display: none;

    img {
        border: 2px solid #5050ba;
        max-width: 250px;
        //box-shadow: 0 4px 60px 0 #000000c4;
        width: 100%;
        border-radius: 8px;
        margin: 20px 0 10px 0;
    }
}

.desktop-walkthrough {
    display: flex;
}

.mobile-show {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .dash-button {
        font-size: 0.85em;
    }
}

@media only screen and (min-width: 951px) {

    #login-container {

        height: 100%;

        .login-inner {
            max-width: 1050px;
            height: 100%;
            padding: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;

            .login-panel {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                width: 430px;
            }

            #login-logo.desktop-logo {
                display: inline;
                width: 430px;
            }

            #login-logo.second-desktop-logo {
                display: inline;
                position: relative;
                width: 180px;
                top: 20px;
                margin-bottom: 50px !important;
                margin-left: auto;
                margin-right: auto;
            }

            #login-logo.mobile-logo {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 950px) {

    #login-container {

        .login-inner {

            max-width: 430px;
            display: block;
            height: 800px;
            top: 20px;

            p {
                font-size: 0.9em;
            }

            .mat-tab-label-content {
                white-space: wrap !important;
            }

            #login-logo.mobile-logo {
                display: inline;
                position: relative;
                width: 200px;
                top: 20px;
                margin-bottom: 30px !important;
            }

            #login-logo.desktop-logo {
                display: none;
            }

            #login-logo.second-desktop-logo {
                display: none;
            }

            .terms-break {
                display: inline !important;
            }
        
            .mat-checkbox-inner-container {
                margin: 0 8px 0 0 !important;
                top: 7px !important;
            }
        }
    }    
}

@media only screen and (max-width: 820px) {

    html,
    body {
        transform: none !important;
    }

    /* app-navigation {
        position: static !important;
    } */

    app-accessibility-modal {
        button {
            margin: 5px !important;
        }
    }

    .mobile-hide {
        display: none !important;
    }

    .mobile-show {
        display: flex;
    }

    // Mobile scrolling:
    #app-container {
        position: relative !important;
        display: block !important;
    }

    /* #navigation {
        .bi-x,
        .bi-list {
            font-size: 2.75em !important;
        }
    } */

    .main-title h1 {
        max-width: 100%;
    }

    /* .main-title {
        font-size: 1em;
        line-height: 38px;
        min-height: 79px;
        text-align: center;
        border: none !important;
    }

    .light-mode {
        .main-title {
            border-bottom: none !important;
        }
    } */

    .main-title {
        min-height: 60px !important;
        margin-bottom: 10px !important;
    }

    .page-title {
        position: fixed;
        top: 13px;

        h1 {
            margin: 0px !important;
            position: relative !important;
            left: -55px !important;
        }
    }

    h2.page-title {
        margin: 0 !important;
    }

    #dash-container {
        overflow: auto !important;
        display: block;
    }

    .dash-button-container {
        padding: 7px !important;
        left: -5px;
        position: relative;
    }

    .dash-button { 
        padding: 10px 15px !important;
        min-height: 0 !important;
        font-weight: normal !important;
    }

    .dash-button-icon {
        right: 10px !important;
        font-size: 1em !important;
    }

    .top-right-menu {
        top: 9px !important;

        &.with-org-label {
            top: 5px !important;
        }
    }

    #top-right-org-label {
        top: 40px !important;

        span.org-label-desktop {
            display: none !important;
        }
    
        span.org-label-mobile {
            display: inline !important;
        }
    }

    .card .scroll-y {
        max-height: 500px;
    }

    .list-section {
        padding: 0 !important;
    }

    .activity-card {
        padding: 15px !important;
    }

    .list-section-container {
        flex-direction: column !important;
        overflow: visible !important;
        height: auto !important;
    }

    .help-text[_ngcontent-lkm-c253] {
        display: none;
        position: fixed;
        bottom: auto !important;
        left: 20px;
        width: calc(100% - 40px) !important;
        top: -20px !important;
    }

    .helper-icon {
        .help-text {
            left: 50% !important;
            transform: translateY(100%) translatex(-50%) !important;
        }
    }

    

    /* #navigation span {
        display: none !important;
    } */

    #qr-button,
    #org-button,
    #user-menu {
        i {
            margin-left: 0;
        }
        span {
            display: none;
        }
    }

    .awards-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    .desktop-walkthrough {
        display: none;
    }

    /* .copilot-view {
        position: fixed;
        left: 50% !important;
        max-width: none !important;
        justify-content: center;
        animation: none !important;
        height: calc(100vh - 177px);
        min-height: calc(100vh - 177px);
        align-items: center;
        margin: 0 !important;
        width: calc(100vw - 60px) !important;
        top: 50% !important;
        -webkit-transform: translate(-50%, -50%) !important;
        -moz-transform: translate(-50%, -50%) !important;
        -o-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }

    .copilot-custom {
        margin: auto 0;
        position: fixed;
        top: 50%;
        height: 100%;
        display: flex;
        left: 50%;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        transform: translate(-50%, -50%);
    } */

    .copilot-view {
        width: 80% !important;
        margin: 0 auto !important;
        padding: 30px 10px 10px 10px !important;

        -webkit-transform: translate(-50%, -50%) !important;
        -moz-transform: translate(-50%, -50%) !important;
        -o-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }

    .copilot-custom {
        margin: auto !important;
        font-size: 0.9em !important;
    }

    .copilot-target {
        position: fixed !important;
        left: 50% !important;
        margin: 0 !important;
        top: 50% !important;
        width: 0 !important;
        height: 0 !important;
        transform: translate(-50%, -50%) !important;
    }

    .logged-in-dash {
        padding: 0 0 35px 0 !important;
    }

    /* #logo {
        position: fixed;
        width: 42px !important;
        top: 22px;
        left: 20px;
    }

    #logo {
        z-index: 9999;
        top: 7px !important;
    } */

    #logo {
        z-index: 9999;
        position: fixed;
        top: 5px !important;
        left: 10px !important;
    }

    .login-info-text {
        font-size: 0.85em;
    }

    .dashboard-bottom {
        margin: 0 !important;
        padding: 20px 0 !important;
        display: block;
    }

    .back-button {
        bottom: 3px !important;
        left: 5px !important;
        z-index: 99999999 !important;
        background: transparent !important;
    }

    .footer {
        span {
            display: none;
        }

        .footer-accessibility {
            display: block;
        }
    }

    .logged-in-footer {
        display: none !important;

        .footer-accessibility {
            display: none !important;
        }
    }

    

    .mat-tab-label {
        padding: 0 !important;
        //max-width: 40px !important;
    }

    .mat-tab-label-content {
        font-size: 0.85em;
    }

    #course-tabs .mat-tab-body-content {
        overflow: hidden !important;
    }

    .card-title {
        margin: 0px !important;
        min-height: 10px !important;
    }

    .index-list {
        h2 {
            display: none;
        }
    }

    //Important info box
    .important-info-box {
        margin: 10px 0 !important;
        padding: 10px !important;
        font-size: 0.85em !important;
        /* border-radius: 8px; */

        &.clash {
            margin: 0px !important;
            padding: 5px !important;
        }
      
        h2 {
          font-size: 1em;
        }
      
        .card-title {
          /* font-weight: 400; */
          font-size: 1em;
          /* margin-top: 0 !important;
          padding-top: 0;
          margin-bottom: 10px !important; */
        }
      
        /* * {
          color: #fff;
        } */
    }

    //Inputs
    label,
    input,
    select,
    textarea,
    .mat-select,
    .mat-form-field-appearance-legacy
    .mat-form-field-label {
        font-size: 0.85em !important;
    }
    
    //Override? Remove if not
    .fake-dropdown .mat-form-field-prefix, .mat-form-field-suffix {
        top: -16px;
    }
    
    .date-input.fake-dropdown input,
    input:not([type=radio]):not(.mat-date-range-input-inner):not([type=range]):not(.mat-datepicker-input):not([type=time]),
    input[type=file],
    input[type=password],
    .native-textarea,
    .native-textarea.sc-ion-textarea-md,
    textarea,
    ion-select,
    select,
    .native-input {
        padding: 10px !important;
        margin-top: 4px;
    }

    .mat-select {
        padding: 10px !important;
        border-radius: 4px !important;  
        margin-top: 5px;  
    }
    
    /* .mat-datepicker-input {
        padding: 5px !important;
    } */
    
    //Override? Remove if not
    textarea {
        width: 100%;
        display: block;
        border: none !important;
        //height: 50px;
        padding: 5px !important;
    
        &:focus {
            border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
        }
    }

    #reflections textarea {
        height: 200px;
    }

    .attendance-label {
        padding-top: 10px;
        font-size: 0.85em;
    }
    
    //Override? Remove if not
    ion-label.mt15 {
        margin-top: 15px !important;
    }

    //Override? Remove if not
    ion-label {
        font-size: 0.85em !important;
        padding: 0px !important;
    }
    
    //Override? Remove if not
    .filter-wrapper {
        font-size: 0.85em !important;
    
        .filter-label {
            padding: 5px 0 0 5px;
        }
    
        ion-label {
            padding: 10px;
        }
    
        .filter-value {
            padding: 10px;
            font-size: 0.85em !important;
        }
    
        /* .datepicker-trigger {
            padding: 0 20px;
        } */
    }
    
    /* //Override? Remove if not
    .mat-form-field-flex {
        margin-top: 10px;
    } */
    

    //Override? Remove if not
    ion-icon.searchbar-search-icon {
        right: 8px !important;
        position: absolute !important;
        display: flex;
        left: auto !important;
        top: 50% !important;
        transform: translateY(-50%);
        color: #fff !important;
    }
    
    //Override? Remove if not
    .searchbar-input,
    .searchbar-input.sc-ion-searchbar-ios,
    input.searchbar-input.sc-ion-searchbar-md {
        padding-left: 35px !important;
        color: #fff !important;
    }
    



    //Override? Remove if not
    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        height: 5px !important;
        padding: 0 !important;
        background: #5050ba !important;
        outline: none;
    }

    //Override? Remove if not
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #5050ba;
        cursor: pointer;
    }
    
    //Override? Remove if not
    input[type=range]::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #5050ba;
        cursor: pointer;
    }
    


    //Override? Remove if not
    .filter-value {
        padding: 5px 10px;
        font-size: 0.9em;
        background: #ffffff17;
        border-radius: 4px;
    }

    //Override? Remove if not
    /* .alert-button-inner {
        font-size: 1.23em;
    } */

    
    .mat-form-field-label-wrapper {
        top: -20px;
    }
    
    //Override? Remove if not
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    }
    
    //Override? Remove if not
    .mat-date-range-input-mirror {
        min-width: 55px !important;
    }
    
    //Override? Remove if not
    .mat-select-panel-wrap {
        box-shadow: 0 0 5px 0 #00000042;
    }
    

    
    //Override? Remove if not
    .select-popup-overlay {
        position: relative;
    
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: block;
            z-index: 90;
        }
    }

    input[type="time"] {
        padding: 5px !important;
        margin-top: 4px;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        font-size: 1.5em !important;
    }
    
    .mat-option {
        white-space: normal !important;
        line-height: normal !important;
        height: unset !important;
        padding-top: 0.9em !important;
        padding-bottom: 0.9em !important;
        font-size: 13px !important;

        span {
            font-size: 13px !important;
        }
    }

    //Override? Remove if not
    .required-fields-explainer {
        font-size: 0.85em;
    }
    

    //Override? Remove if not
    .mat-radio-input {
        margin: 0 !important;
    }
    
    .mat-form-field-label {
        margin-top: 2px;
    }

    .mat-datepicker-content .mat-calendar {
        max-width: 80vw !important;
        height: 100% !important;
    
        span {
            font-size: 0.9em !important;
            /* padding: 7px !important; */
        }
    }

    .inHeader {
        .mat-form-field-label {
            margin-top: 0 !important;
        }
    
        .card-title {
            margin-top: 0 !important;
        }
    }













    /* .button {
        padding: 5px 10px;
        font-size: 0.8em !important;
        text-align: center;
        display: flex;
        position: relative;
        background: transparent;
        max-width: 400px;
        font-weight: 560;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        min-height: 47px;
        margin: 2px;
        transition: all 0.2s;
        color: #fff;
    
        &.active-tab{
            position: relative;
        }
        &.active-tab:before{
            content: '';
            height: 2px;
            display: block;
            background-color: #fff;
            position: absolute;
            width: 100%;
            bottom: 8px;
        }
    
        &.small-button{
            min-height: 0;
            padding: 5px 10px !important;
            *{
                color: #fff !important
            }
            i{
                height: 19px;
            }
        }
    
        &.filter-button{
            min-height: 0;
            padding: 2px 5px !important;
            *{
                color: #fff !important
            }
            i{
                height: 19px;
            }
        }
    
        &.p5{
            min-height: 0;
            padding: 5px !important;
        }
    
        span:not(.mat-badge-content) {
            transition: all 0.2s;
            display: flex;
            color: #fff;
            position: relative;
            padding: 0 10px;
            height: 100%;
            display: flex;
            align-items: center;
        }
    
        i {
            transition: all 0.2s;
            display: flex;
            font-size: 1.6em !important;
        }
    
        &.tab{
            padding: 10px 10px;
        }
    
        &.card{
            margin: 4px auto;
        }
    
    } */

    .button {

        min-height: 0;
        padding: 5px;

        span {
            padding: 5px !important;
        }

        i {
            margin: 0 !important;
        }

        &.card-orgs {
            * {
                font-size: 0.85em !important;
            }
            padding: 10px !important;
            min-height: 40px  !important;
        }

        &.announcements-bell {
            right: 150px;
            top: 10px;

            i span {
                padding: 0px;
            }
        }
    }

    #message {
        font-size: 12px;
    }

    .alert-button {
        padding: 5px 10px !important;

        span {
            padding: 5px !important;
        }
    }
    
    .inkpath-modal {
        width: 100% !important;
        height: 100% !important;
        margin: auto !important;
    }

    .dash-card {
        padding: 10px !important;

        a {
            font-size: 0.85em;
        }
    }

    .dash-card-text {
        font-size: 0.85em;
    }

    .modal-text {
        font-size: 0.85em;
    }

    .alert-checkbox-label.sc-ion-alert-md {
        font-size: 0.85em;
    }

    .alert-head.sc-ion-alert-md {
        padding-top: 0px;
    }
}



@media only screen and (max-width: 520px) {
    .page-title {
        position: relative;
        top: 0px;

        h1 {
            display: flex !important;
            margin: 0px !important;
            left: 0px !important;
        }
    }
}



@media only screen and (max-width: 500px) {
    .main-title {
        font-size: 0.875em;
    }

    .top-progress-bar {
        flex-direction: column;

        .progress-bar {
            margin-bottom: 15px;
            flex: auto;
            width: 100%;
        }

        .jump-to {
            width: 100%;
            padding: 0;
        }
    }
}




@media only screen and (max-width: 365px) {

    .dash-button {
        padding: 10px 0 !important;
        text-align: center !important;
    }

    .dash-button-icon {
        display: none !important;
    }

    .footer {
        .inkpath {
            display: none;
        }
    }
}


@media only screen and (max-width: 284px) {

    #login-container .login-inner .terms-break2 {
      display: inline !important;
    }

}


// Accessibility for browser zoom
@media only screen and (max-width: 270px) {

    ion-modal .ion-page {
        max-height: 100vh !important;
        overflow: auto !important;
    }

    app-accessibility-modal {
        button {
            margin: 5px !important;
        }
    }

    #navigation .back-button {
        background: #212733 !important;
        border-radius: 0 8px 0 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }

    .activity-tabs {
        flex-direction: column;
        text-align: center;
    }

    .inkpath-modal {

        page-activity-edit {
            .flex-1 {
                flex: none !important;
            }
        }

        .full-height {
            overflow: auto !important;
        }
    }

}

//Zoom fix - 300%
@media only screen and (max-height: 400px) {

    .back-button {
        bottom: -2px !important;
    }

    .logged-in-dash {
        padding: 0 0 27px 0 !important;
    }

    .copilot-custom {
        min-height: 0px;
    }

    .copilot-view {
        top: 180px !important;
        height: 240px;
    }
}

//Zoom fix - 400%
@media only screen and (max-height: 300px) {

    .copilot-view {
        top: 140px !important;
        height: 160px;
    }

}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */

    body.dynamic-island {
        top: 40px;

        .top-right-menu {
            top: 50px !important;
        }

        #navigation {
            padding-bottom: 45px !important;
        }

        .logged-in-dash {
            padding: 0 0 45px 0 !important;
        }

        .full-height {
            padding-bottom: 10px !important;
        }

        #mobile-menu-button {
            bottom: 16px !important;
        }

        .back-button {
            bottom: 14px !important;
        }
    }

    .helper-icon {
        display: none !important;
    }
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
}