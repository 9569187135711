/* Global Resetting */
html,
body {
  width: 100%;
  float: left;
  height: 100%;
  margin: 0;
  font-size: 16px;
  padding: 0;
  background-color: #e3ebf0;
  transition: all 350ms;
  font-family: 'Poppins', sans-serif;
  *:not(text){
    font-size: 1em;
  }
}


html.translated_ltr {
  color: red;
}

.bold{
  font-weight: bold !important;
}

* {
  //font-size: 1em;
  /* reset font-sizes to 1em == 10px */
  padding: 0;
  margin: 0;
  text-decoration: none;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: none;
}

// General
.oh{
  overflow: hidden;
}
.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}

.clear {
  clear: both;
}

.devShow {
  box-shadow: 0px 0px 0px 1px #000;
}

font {
  font-family: inherit !important;
  color: inherit;
}

.pointer{
  cursor: pointer;
}

// Text Highlighting
::-moz-selection {
  background: #69739d;
  color: #fff !important;
}

::selection {
  background: #69739d;
  color: #fff !important;
}


// Inline block
.dib {
  display: inline-block;
  vertical-align: middle;
}

// Flex columns and rows
.flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
}

.col {
  display: flex;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

// Scrolling
.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-x {
  overflow-y: hidden;
  overflow-x: auto;
}

// Position
.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

.fixed{
  position: fixed;
}

.sticky{
  position: sticky;
}

// Columns
$columns: 12;

@for $i from 1 through $columns {
  $width: calc($i / $columns * 100%);
  .col-#{$i} {
    display: flex;
    position: relative;
    width:  $width;
  }
}

@for $i from 1 through $columns {
  .flex-#{$i} {
    flex: $i;
  }
}

// Margins and padding
@for $i from 1 through 10 {
  $px: #{(5*$i)}px;

  .p#{(5*$i)} {
    padding: $px;
  }

  .pl#{(5*$i)} {
    padding-left: $px;
  }

  .pr#{(5*$i)} {
    padding-right: $px;
  }

  .pt#{(5*$i)} {
    padding-top: $px;
  }

  .pb#{(5*$i)} {
    padding-bottom: $px;
  }

  .m#{(5*$i)} {
    margin: $px;
  }

  .ml#{(5*$i)} {
    margin-left: $px;
  }

  .mr#{(5*$i)} {
    margin-right: $px;
  }

  .mt#{(5*$i)} {
    margin-top: $px;
  }

  .mb#{(5*$i)} {
    margin-bottom: $px;
  }
}

// Simple CSS Grid (apply this class to the parent for a simple responsive grid)
.grid {
  grid-gap: 10px;
  display: grid;
  grid: auto-flow dense auto / repeat(auto-fit, minmax(300px, 1fr));
}

// Wrapping
.stack {
  min-width: 300px;
}

@for $i from 2 through 20 {
  .stack#{(24*$i)} {
    min-width: #{(24*$i)}px;
  }

  .stack#{(25*$i)} {
    min-width: #{(25*$i)}px;
  }

  .grid#{(24*$i)} {
    height: 100%;
    display: grid;
    grid: auto-flow dense auto / repeat(auto-fit, minmax(#{(24*$i)}px, 1fr));
  }
}

// Spacing
.space-around {
  justify-content: space-around;
  align-content: space-around;
}

.space-between {
  justify-content: space-between;
  align-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
  align-content: space-evenly;
}

// Alignment
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

// Wrapping
.flex-nowrap {
  flex-wrap: nowrap;
}
.nowrap {
  white-space: nowrap;
}

// Center column
.inner {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// Icons sizes
$maxIconSize: 200;
$smallest: 8;
$multiplesOfEight: 33;

@for $i from 1 through $maxIconSize {
  @if $i < $multiplesOfEight and $i>$smallest {
    .iconSize#{$i} {
      font-size: #{$i}px;
      width: #{$i}px !important;
      height: #{$i}px !important;
      line-height: #{$i}px;
    }
  }

  @else {
    @if $i % $smallest==0 {
      .iconSize#{$i} {
        font-size: #{$i}px;
        width: #{$i}px !important;
        height: #{$i}px !important;
        line-height: #{$i}px;
      }
    }
  }
}

// Mobile - stack sizes to full width
@media screen and (max-width: 400px) {
  *[class*="stack"] {
    min-width: 100% !important;
  }

  .grid {
    grid: auto-flow dense auto/repeat(auto-fit, minmax(200px, 1fr));
  }
}
