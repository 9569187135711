app-injected-wrapper {
    display: none;
}

.swiper .swiper-slide {
    opacity: 0 !important;
}

.swiper .swiper-slide-active {
    opacity: 1 !important;
}

button.mat-menu-item {
    background: transparent;
}

.card-title {
    min-height: 20px;
}
.mat-ink-bar {
    background: #ffffff63;
}

//Moved from brandings
.inkpath-modal {
    --height: auto !important;
    padding-bottom: 10px;
    --ion-background-color: transparent;

    .ion-page {
        background: #202637 !important;
        overflow-y: auto !important;
    }
}

.inkpath-modal .ngx-wrapper-overview {
    display: none !important;
}

.datepicker-trigger {
    display: none;
}

.important-info-box {
    &.clash {
        //margin: 20px 0;
        background-color: #b86000 !important;
        padding: 10px;
        margin: 0px auto;
        font-size: 0.85em;
        text-align: center;
        border-radius: 8px;
        border: none;
        white-space: pre-line;
    }
}

/* .progress-activity .important-info-box {
    padding: 4px 10px;
} */

.mat-date-range-input-separator {
    color: #fff;
}

.hiddenText {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

h3 {
    margin: 0px !important;
}

.new-window {
    font-size: 12px !important;
    cursor: pointer;
}

.col-12 li,
.grid li {
    display: flex;

    app-dash-button {
        width: 100%;
        display: flex;
        margin: 0 5px;
    }
}

.grid li {
    list-style: none !important;
}

.main-title {
    h1 {
        margin: 0px !important;
    }

    .helper-icon {
        margin-top: -10px;
    }
}

.footer {
    li {
        padding: 0px 4px;
    }
}



.searchbar-clear-icon {
    color: #fff;
    margin-right: 20px;
}

u {
    text-decoration: underline !important;
}

// Sections
.custom-content {
    //max-width: calc(100% - 115px);

    * {
        font-size: 1em !important;
    }

    .more {
        display: none;
    }

    ul li {
        margin-left: 20px;
        list-style-type: circle;
        display: list-item !important;
    }

    ol li {
        margin-left: 20px;
        display: list-item !important;
        list-style-type: decimal !important;
        list-style: decimal;
    }
    ul,ol{
        width: 100%;
    }

    p{        
        width: 100%;
    }

    h1 {
        font-size: 1.5em !important;
        width: 100%;
    }

    h2 {
        font-size: 1.3em !important;
        width: 100%;
    }

    h3 {
        font-size: 1.1em !important;
        width: 100%;
    }

    strong,
    b{
        font-weight: bold !important;
    }

}


.password-note {
    font-size: 0.8em;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    border-radius: 4px;

    li {
        list-style-type: disc;
    }
}


.modal__header ol {
    padding-left: 20px !important;

    li {
        list-style-position: initial !important;
        list-style-image: initial !important;
        list-style-type: decimal !important;
        display: list-item !important;
    }
}

.modal__header ul {
    padding-left: 20px !important;

    li {
        list-style-position: initial !important;
        list-style-image: initial !important;
        list-style-type: disc !important;
        display: list-item !important;
    }
}

.terms-alert p {
    margin: 1em 0 !important;
    line-height: normal !important;
}

.terms-alert i {
    font-size: 1em !important;
}

.terms-alert li {
    margin: 0.5em 0 !important;
}

.terms-alert u {
    text-decoration: underline !important;
}

.terms-alert ul,
.terms-alert ol {
    padding-left: 20px !important;
    margin: 1em 0 !important;
}

.terms-alert-chk {
    text-align: center;
}

.terms-break, .terms-break2 {
    display: none;
}

.more-dates {
    margin: 10px 0 0 0 !important;
}

.more-dates a {
    cursor: pointer;
}

// Badge
.mat-badge-warn .mat-badge-content {
    background: #8d168c;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    padding: 0 !important;
}

video{
    width: 100% !important;
    z-index: 99999999 !important;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100% !important;
}

.dash-card {
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    background: hsla(231, 36%, 39%, 0.612);
    box-shadow: 0px 8px 30px 0px hsla(222, 32%, 10%, 0.329);
    position: relative;
    overflow: hidden;
    transition: all 0.2s;
    transform: scale(1);
  
    .dash-title {
        font-size: 0.85em;
        color: #fff;
        font-weight: bold;
    }
  
    p {
        font-weight: normal;
        font-size: 0.85em;
        color: #ffffffc4;
        border-radius: 4px;
    }
}

.dash-card-text {
    white-space: pre-line;
}

.event-title {
    font-size: 1.3em;
}

#message {
    font-size: 14px;
    padding: 15px;

    p, div, ul, ol {
        margin-bottom: 10px !important;
    }

    li {
        margin-left: 40px;
        margin-bottom: 0px !important;
        display: list-item;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
        list-style-position: outside;
    }
}